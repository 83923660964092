import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal/Modal";

const Posts = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    setShow(true)
  }, [])
  return (
    <>
      <Modal onClose={() => { setShow(false); setTimeout(()=> {navigate('/')}, 200) } } show={show}>
      </Modal>
    </>
  );
};

export default Posts;
