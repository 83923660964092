// import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Main from "./Main";

const Posts = () => {
//   const [show, setShow] = useState(false);
  return (
    <>
      <Header />
      <Main />
      <Outlet />
    </>
  );
};

export default Posts;
