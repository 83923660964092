import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import SinglePost from "./components/SinglePost";
import Navbar from "./components/Navbar";
import NotFound from "./components/NotFound";
import Posts from "./components/Posts";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Posts />}>
          <Route path=":title" element={<SinglePost />} />
        </Route>
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/not-found" />} />
      </Routes>
    </div>
  );
}

export default App;
