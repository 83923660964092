import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import "./Modal.css";
import 'react-toastify/dist/ReactToastify.css';

const RegisterModal = (props) => {
  const [loading, setLoading] = useState(false)
  const email = useRef(null);

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    if(email.current.value) {
      try {
        const response = await axios.post("https://modules.platoservices.ir/api/Newslatter", { email: email.current.value}, { headers: {'Domain': "kharaei.platolms.ir"} })
        if(response.data.status) {
          setLoading(false)
          // toast S
          props.handleNotif('s')
        } else {
          setLoading(false)
          // toast F
          props.handleNotif('f')
        }
      } catch (error) {
        setLoading(false)
        console.log('errorHandler:', error)
      }
    }
  };
  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  });

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 150 }}
    >
      <div className="modal" onClick={props.onClose}>
        <span className="modal-close" onClick={props.onClose}>
          <i className="fas fa-times"></i>
        </span>
        <div
          className="modal-content modal-content-sm"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-body">
            <div className="col">
              <h3 className="newsletter-title">دنبال کردن</h3>
              <form onSubmit={handleSubmit}>
                <p>
                  لطفاً ادرس ایمیلتون رو وارد کنید:
                </p>
                <input type="email" ref={email} required />
                <button disabled={loading}>تایید</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default RegisterModal;
