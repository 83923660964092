import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import "./Modal.css";
import Skeleton from "react-loading-skeleton";

const Modal = (props) => {
  const [baseInfo, setBaseInfo] = useState({});
  const [post, setPost] = useState({})

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  const getBaseInfo = async () => {
    const response = await axios.get(
      "https://kharaei.ir/api/instagram/layout"
      //, { headers: {'Content-Type': "application/json; charset=utf-8"} }
    );
    if (response.data.isSuccess === true) {
      setBaseInfo(response.data.data);
    }
  };

  const getPost = async () =>  {
    const response = await axios.get(`https://kharaei.ir/api/instagram/post/${url}`
    //, { headers: {'Content-Type': "application/json; charset=utf-8"} }
    );
    if (response.data.isSuccess === true) {
      setPost(response.data.data);
    }
  };
  const { url } = useParams();

  useEffect(() => {
    getBaseInfo();
    getPost();
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 150 }}
    >
      <div className="modal" onClick={props.onClose}>
        <span className="modal-close" onClick={props.onClose}>
          <i className="fas fa-times"></i>
        </span>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-body">
            <div className="col modal-image">
              <div style={{backgroundImage: `url(${(post.image && `${post.image}`) || "./no-image.jpeg"})`}}></div>
            </div>
            <div className="col modal-details">
              <div className="modal-head">
                {!post.title ? (
                  <>
                    <Skeleton
                      circle={true}
                      width={32}
                      height={32}
                      style={{ marginTop: "5px", marginLeft: "15px" }}
                    />
                    <div style={{display: "flex"}}>
                      <Skeleton width={80} />
                      <Skeleton width={60} />
                    </div>
                  </>
                ) : (
                  <>
                    <img src={baseInfo.picture} alt={baseInfo.siteTitle}></img>
                    <div>
                      <span>{post.title && post.title}</span>
                      <small>{post.dateTime && `${post.dateTime.split(' ')[0]}  ${post.dateTime.split(' ')[1]}`}</small>
                    </div>
                  </>
                )}
              </div> 
              <div className="modal-text">
                <div dangerouslySetInnerHTML={{ __html: post.text }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default Modal;
