import { Link } from "react-router-dom";
import "./Post.css";

const Post = (props) => {
  return (
    <>
      <Link className="gallery-item" tabIndex="0" to={`/${props.content.url}`}>
        {props.content.image ? (
          <img
            src={`https://insta.kharaei.ir/storage/${props.content.image}`}
            className="gallery-image"
            alt={props.content.image}
          />
        ) : (
          <img
            src="./no-image.jpeg"
            className="gallery-image"
            alt="nothing"
          />
        )}

        <div className="gallery-item-info">
          <ul>
            <li className="gallery-item-likes">
              <i className="fas fa-search" aria-hidden="true"></i>
            </li>
          </ul>
        </div>
      </Link>
    </>
  );
};

export default Post;
