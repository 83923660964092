import { Component } from "react";
import "./Navbar.css";

class Navbar extends Component {
  state = {};
  render() {
    return (
      <nav>
        <div className="container">
          <img src="./logo.png" width="120px" alt="رضا خارائی" />
        </div>
      </nav>
    );
  }
}

export default Navbar;
