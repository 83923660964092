import { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './Loading.css';

class Loading extends Component {
    render() { 
        return Array(9).fill({}).map((_,index) => (
            <div className='loading-box' key={index}>
                <Skeleton style={{paddingTop: "90%"}} />
            </div>
        ));
    }
}
 
export default Loading;